import { React, useEffect, useState } from "react";
import { Button, Container, Tab, Row, Col } from "react-bootstrap";
import CommonLayout from "./commonLayout";
import {
  ParticipantsTable,
  TeamTable,
  Group,
  SessionModal,
} from "@/components";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetParticipant,
  useGetTeams,
  useGetSingleSessions,
} from "@/customHooks/index";
import { supabase } from "@/config/supabase";
import { decrypt, encrypt } from "@/utils/crypto";
import { useFetchTeamParticipant } from "@/customHooks/useTeam";
import { usePatchParticipantData } from "@/customHooks/useParticipants";
import { constant } from "@/utils/constant";

const Participants = ({
  isAscending,
  handleAscending,
  handleShow,
  show,
  refetch,
  isLoggedIn,
  socket,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const getTeam = useGetTeams(Number(decrypt(params?.id)));
  const [isRandomBtn, setIsRandomBtn] = useState(false);
  const [eventKey, setEventKey] = useState("participants");
  const [buttonDisable, setButtonDisable] = useState("");
  const { data } = useGetSingleSessions(Number(decrypt(params?.id)));
  const participantData = useGetParticipant(Number(decrypt(params?.id)));
  const getTeamParticipant = useFetchTeamParticipant(
    Number(decrypt(params?.id)),
  );
  const { id: session_id } = useParams();
  const getSession = useGetSingleSessions(Number(decrypt(session_id)));
  const { setIsParticipantDataUpdate } = usePatchParticipantData();
  const sessionData = getSession?.data || {};
  const session = sessionData?.session || {};
  const [sessionId, setSessionId] = useState();

  useEffect(() => {
    setSessionId(Number(decrypt(params?.id)));
    setEventKey(params.dynamicPart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  // Calculate unassign participant.
  const unassigned =
    participantData?.data &&
    participantData?.data.length !== 0 &&
    !session?.is_open_game_link;

  const handleTabSelect = (selectedEventKey) => {
    navigate(`/${selectedEventKey}/${encrypt(String(sessionId))}`);
    setEventKey(selectedEventKey);
  };

  const addRoleRandom = async (insertPromises, desiredRoles, teamId) => {
    const { data } = await supabase
      .from("team_participant")
      .select(
        "id, role_id, participant_id, team_id, session_id, current_status, is_game_leave",
      )
      .eq("session_id", Number(decrypt(params?.id)));
    const assignedRoles = data.map((obj) => obj.role_id);
    const assignedRolesIn = insertPromises
      .filter((obj) => obj.team_id === teamId)
      .map((obj) => obj.role_id);
    for (const role of desiredRoles) {
      if (!assignedRoles.includes(role) && !assignedRolesIn.includes(role)) {
        return role;
      }
    }
    return null;
  };
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  const addRandomAssignParticipant = async (session_id) => {
    try {
      // Fetch team_participant data and participants data concurrently
      const [teamPartResult, participantsResult, teamsResult, rolesResult] =
        await Promise.all([
          supabase
            .from("team_participant")
            .select()
            .eq("session_id", session_id),
          supabase.from("participant").select().eq("session_id", session_id),
          supabase.from("team").select().eq("session_id", session_id),
          supabase.from("role").select(),
        ]);
      if (
        teamPartResult.error ||
        participantsResult.error ||
        rolesResult.error ||
        teamsResult.error
      ) {
        throw new Error("Failed to fetch data from the database.");
      }
      const teamParticipant = teamPartResult?.data;
      const participants = participantsResult?.data;
      const teams = teamsResult?.data;
      const roles = rolesResult?.data?.map((item) => item.id);

      const teamParticipantUnAssign = teamParticipant.filter(
        (obj) => obj.participant_id === null,
      );
      const teamAssignParticipantIds = teamParticipant
        .filter((obj) => obj.participant_id !== null)
        .map((obj) => obj.participant_id);

      const unAssignParticipants = participants.filter(
        (participant) => !teamAssignParticipantIds.includes(participant.id),
      );

      const teamAssignParticipant = teamParticipant.filter(
        (obj) => obj.participant_id !== null,
      );
      const updateData = [];
      const idsToRemove = [];

      // if already added then update
      if (teamParticipantUnAssign.length === 0) {
        const allParticipantIds = teamParticipant.map(
          (obj) => obj.participant_id,
        );
        shuffleArray(allParticipantIds);
        for (let i = 0; i < teamParticipant.length; i++) {
          updateData.push({
            id: teamParticipant[i]?.id,
            participant_id: allParticipantIds[i],
          });
          idsToRemove.push(allParticipantIds[i]);
        }
        // update team_participant table
        await supabase
          .from("team_participant")
          .upsert(updateData, { onConflict: ["id"] });
      } else if (participants.length === teamAssignParticipant.length) {
        const updateDataBlank = [];
        const randomParticipantTeamIds = teamParticipant.map((obj) => obj.id);
        shuffleArray(randomParticipantTeamIds);
        for (let i = 0; i < teamAssignParticipant.length; i++) {
          if (randomParticipantTeamIds[i]) {
            updateDataBlank.push({
              id: teamAssignParticipant[i].id,
              participant_id: null,
            });
            updateData.push({
              id: randomParticipantTeamIds[i],
              participant_id: teamAssignParticipant[i].participant_id,
            });
            idsToRemove.push(randomParticipantTeamIds[i]);
          }
        }
        await supabase
          .from("team_participant")
          .upsert(updateDataBlank, { onConflict: ["id"] });
        await supabase
          .from("team_participant")
          .upsert(updateData, { onConflict: ["id"] });
      } else {
        // if not added then update
        const randomIds = unAssignParticipants.map((obj) => obj.id);
        shuffleArray(randomIds);
        for (let i = 0; i < teamParticipantUnAssign.length; i++) {
          if (unAssignParticipants.length > i && randomIds[i]) {
            updateData.push({
              id: teamParticipantUnAssign[i]?.id,
              participant_id: randomIds[i],
            });
            idsToRemove.push(randomIds[i]);
          }
        }
        // update team_participant table
        await supabase
          .from("team_participant")
          .upsert(updateData, { onConflict: ["id"] });
      }
      // Filter remaining participant list.
      const stillUnAssignParticipants = unAssignParticipants.filter(
        (item) => !idsToRemove.includes(item.id),
      );

      const insertPromises = [];
      let teamIndex = 0;
      // get data for insert
      for (let i = 0; i < stillUnAssignParticipants.length; i++) {
        const element = stillUnAssignParticipants[i];
        if (teams.length === 1) {
          await addRoleRandom(insertPromises, roles, teams[0].id).then(
            (remainingRole) => {
              if (remainingRole !== null) {
                const role_id = remainingRole;
                const team_id = teams[0].id;
                insertPromises.push({
                  role_id: role_id,
                  team_id: team_id,
                  session_id: session_id,
                  participant_id: element.id,
                });
              }
            },
          );
        } else if (teams.length > 1) {
          if (stillUnAssignParticipants.length > i) {
            const team_id = teams.filter((item) => {
              if (item.team_index === (i % teams.length) + 1) {
                return item.id;
              }
            });
            await addRoleRandom(insertPromises, roles, team_id[0].id).then(
              (remainingRole) => {
                if (remainingRole !== null) {
                  const role_id = remainingRole;
                  insertPromises.push({
                    role_id: role_id,
                    team_id: team_id[0].id,
                    session_id: session_id,
                    participant_id: element.id,
                  });
                  teamIndex++;
                  if (teamIndex >= teams.length) {
                    teamIndex = 0;
                  }
                }
              },
            );
          }
        }
      }
      // insert all extra role
      await supabase.from("team_participant").upsert(insertPromises);

      participantData.refetch();
      getTeam.refetch();
      getTeamParticipant.refetch();
      setIsParticipantDataUpdate(true);
      setIsRandomBtn(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    supabase
      .channel("custom-update-channel")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "team_participant" },
        () => {
          participantData.refetch();
          getTeam.refetch();
        },
      )
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "team" },
        () => {
          getTeam.refetch();
        },
      )
      .subscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const assignRandomParticipant = async () => {
    setIsRandomBtn(true);
    await addRandomAssignParticipant(Number(decrypt(params?.id)));
  };

  return (
    <>
      <Tab.Container id="tab" activeKey={eventKey} onSelect={handleTabSelect}>
        <CommonLayout
          isAscending={isAscending}
          handleAscending={handleAscending}
          handleShow={handleShow}
          session_id={Number(decrypt(params?.id))}
          activeKey={eventKey}
          refetch={refetch}
          socket={socket}
        >
          <Container className="mt-10">
            <Tab.Content>
              <Tab.Pane eventKey="participants">
                {unassigned && (
                  <Button
                    variant="outline-primary"
                    className="mb-8"
                    disabled={buttonDisable === 0 || isRandomBtn}
                    onClick={() => assignRandomParticipant()}
                  >
                    {constant?.randomizeAssignments}
                  </Button>
                )}
                <ParticipantsTable
                  setEventKey={setEventKey}
                  buttonDisable={setButtonDisable}
                  sessionData={data}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="teams">
                <Row className="gy-20 gx-lg-20 mb-10">
                  {getTeam?.data?.length ? (
                    getTeam?.data?.map((item, index) => {
                      return (
                        <Col lg={6} key={index}>
                          <TeamTable
                            teamData={item}
                            sessionData={data}
                            participantData={participantData}
                            refetch={getTeam.refetch}
                            socket={socket}
                          />
                        </Col>
                      );
                    })
                  ) : (
                    <Col lg={6}>
                      <TeamTable />
                    </Col>
                  )}
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="group">
                <Group
                  eventKey={eventKey}
                  setEventKey={setEventKey}
                  getTeam={getTeam}
                  sessionData={session}
                  isLoggedIn={isLoggedIn}
                />
              </Tab.Pane>
            </Tab.Content>
          </Container>
        </CommonLayout>
        {show && (
          <SessionModal
            show={show}
            hide={() => handleShow()}
            refetch={refetch}
            handleAscending={handleAscending}
            isAscending={isAscending}
          />
        )}
      </Tab.Container>
    </>
  );
};
export default Participants;
