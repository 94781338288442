import { React } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useGameUpdateStatus } from "@/customHooks/index";
// import { usePatchButtonText } from "@/customHooks/useSession";
import { modal } from "@/utils/constant";

function ImportantModal({
  session_id,
  hide,
  show,
  setIsButtonDisabled,
  isButtonDisabled,
}) {
  const updateGameStatusMutation = useGameUpdateStatus();
  // const { setButtonText } = usePatchButtonText();
  const handleStart = async () => {
    setIsButtonDisabled(true);
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    await updateGameStatusMutation.mutateAsync({ session_id, timeZone });
    // setButtonText("Game in progress");
    setIsButtonDisabled(false);
    hide();
  };
  return (
    <>
      <Modal show={show} onHide={hide} size="lg" centered>
        <Modal.Header className="bg-secondary" closeButton>
          <Modal.Title>{modal?.importantModalTital}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-10 px-lg-14">
          <p className="mb-3" style={{ textAlign: "justify" }}>
            {modal?.importantModalBody} <b>{modal?.importantModalBreakout}</b>{" "}
            {modal?.importantModalBodyText}
          </p>
          <Row className="mt-8">
            <Col xs="7">
              <Button
                variant="secondary"
                onClick={handleStart}
                className="font-semibold w-full"
              >
                {modal?.importantModalButtonText}
              </Button>
            </Col>
            <Col xs="5">
              <Button
                variant="outline-primary"
                className="font-semibold w-full"
                onClick={hide}
                disabled={isButtonDisabled}
              >
                {modal?.cancle}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ImportantModal;
