import { React } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { modal } from '@/utils/constant';

const RefreshConnection = ({ hide, show, handleRefreshConnection, type }) => {
	return (
		<Modal
			show={show}
			onHide={hide}
			size='lg'
			centered
		>
			<Modal.Header
				className='bg-secondary'
				closeButton
			>
				<Modal.Title>
					{type !== 'single'
						? modal?.refreshAllConnectionTitle
						: modal?.refreshConnectionTitle}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className='py-10 px-lg-14'>
				<p className='mb-3'>
					<b>
						{type !== 'single'
							? modal?.refreshAllConnectionP1
							: modal?.refreshConnectionP1}
					</b>
				</p>
				<p>
					{type !== 'single'
						? modal?.refreshAllConnectionP2
						: modal?.refreshConnectionP2}
				</p>
				<Row className='mt-8'>
					<Col>
						<Button
							variant='secondary'
							className='font-semibold w-full'
							onClick={() => handleRefreshConnection()}
						>
							{type === 'single' ? 'Refresh' : 'Refresh All'}
						</Button>
					</Col>
					<Col>
						<Button
							variant='outline-primary'
							className='font-semibold w-full'
							onClick={hide}
						>
							Cancel
						</Button>
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
	);
};
export default RefreshConnection;
